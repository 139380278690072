import { darken, readableColor } from "polished";
import React from "react";
import { IconType } from "react-icons";
import styled from "styled-components";

export type TyreNoiseLabelProps = {
  text: string;
  tyreClass: string | IconType;
  color: string;
};

const Elem = styled.div<{ color: string }>`
  background-color: ${(props) => props.theme.getSafeColor(props.color)};
  border: 1px solid
    ${(props) => darken(0.2, props.theme.getSafeColor(props.color))};
  border-radius: 3px;
  display: flex;
  overflow: hidden;
  align-items: stretch;
  height: 24px;
  color: ${(props) => readableColor(props.theme.getSafeColor(props.color))};

  & > strong {
    margin-right: 6px;
    font-size: 14px;
    background-color: ${(props) =>
      darken(0.1, props.theme.getSafeColor(props.color))};
    color: white;
    font-weight: 600;
    width: 24px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid
      ${(props) => darken(0.2, props.theme.getSafeColor(props.color))};
    text-align: center;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }

  & > span {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.8;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    padding-top: 1px;
  }
`;

const IconImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
`;

export const TyreClassLabel = ({
  text,
  tyreClass: Icon,
  color,
}: TyreNoiseLabelProps) => {
  let c = Icon;

  if (c === "1") {
    c = "A";
  } else if (c === "2") {
    c = "B";
  } else if (c === "3") {
    c = "C";
  } else if (c === "NA") {
    c = "";
  }

  return (
    <Elem color={color}>
      {typeof c === "function" ? (
        <strong>
          <Icon />
        </strong>
      ) : (
        <strong>{c.endsWith(".svg") ? <IconImage src={c} /> : c}</strong>
      )}
      <span>{text}</span>
    </Elem>
  );
};
