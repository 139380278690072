import axios from "axios";

export const displayMutationError = (err: any) => {
  if (axios.isAxiosError(err)) {
    window.alert(err.response?.data.message.error ?? "Unknown Error");
  } else {
    window.alert("Unknown error");
  }
};

export * from "./accounts";
export * from "./admins";
export * from "./balances";
export * from "./basket";
export * from "./callreports";
export * from "./exclusions";
export * from "./fitments";
export * from "./manufacturers";
export * from "./orders";
export * from "./patterns";
export * from "./products";
export * from "./sellouts";
export * from "./stock";
export * from "./tyreoptions";
export * from "./vehicleManufacturers";
export * from "./imprest";
export * from "./deliveries";
export * from "./closedperiods";
export * from "./financials";
export * from "./jobs";
export * from "./returns";
export * from "./auth";
export * from "./rewards";
export * from "./specialtytyres";
export * from "./casingdeals";
