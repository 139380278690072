import { useSearchAccounts } from "@oaktyres/queries";
import { uniq, sortBy, debounce, uniqueId } from "lodash";
import React, { useMemo } from "react";
import { useState } from "react";
import { FaBuilding } from "react-icons/fa";
import LabelInput from "./LabelInput";
import styled from "styled-components";
import Panel from "./Panel";
import { IconType } from "react-icons";
import Flex from "./Flex";
import Label from "./Label";
import Text from "./Text";
import { useDebounce } from "../utils/useDebounce";
import { useQueue } from "react-use";
import { useQuery } from "react-query";

const ListPanel = styled(Panel)`
  position: absolute;
  width: 100%;
  left: 0;
  top: 90px;
  z-index: 2000;
`;

type LabelSearchItem = {
  id: string;
  icon: IconType;
  color: string;
  label: string;
  type: string;
};

const ListItem = ({
  item,
  active,
  ...rest
}: {
  item: LabelSearchItem;
  active: boolean;
} & React.ComponentPropsWithoutRef<"div">) => {
  return (
    <Flex
      p={1}
      style={{ backgroundColor: active ? "#ddd" : "white", cursor: "pointer" }}
      alignItems="center"
      {...rest}
    >
      <Label mr={2} color={item.color} withIcon>
        {React.createElement(item.icon, {
          style: { marginRight: 6 },
        })}
        {item.type}
      </Label>
      <Text mb={0}>{item.label}</Text>
    </Flex>
  );
};

export type LabelSearchInputProps = {
  value: string[];
  onChange: (value: string[]) => void;
  queryFn: (input: string) => Promise<LabelSearchItem[]>;
  formatFn: (input: string) => LabelSearchItem | null;
};

export const LabelSearchInput = ({
  value,
  onChange,
  queryFn,
  formatFn,
}: LabelSearchInputProps) => {
  value = value ?? [];

  const [input, setInput] = useState("");
  const [hasFocus, setHasFocus] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const queryId = useMemo(() => uniqueId(), []);

  const debouncedInput = useDebounce(input, 275);

  const data = useQuery([queryId, debouncedInput], () =>
    debouncedInput.length < 3 ? [] : queryFn(debouncedInput),
  );

  const available = data.data ?? [];

  const selectedItems = value
    .map((x) => formatFn(x))
    .filter((x): x is LabelSearchItem => x != null);

  const select = (i: number) => {
    const item = available[i];
    onChange(uniq([...value, item.id]));
    setInput("");
  };

  const deselect = (code: string) => {
    onChange(value.filter((x) => x !== code));
  };

  const shake = () => {
    window.alert("Invalid Search Term");
  };

  const onKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key === "Enter") {
      ev.preventDefault();
      if (available[activeIndex] != null) {
        select(activeIndex);
      } else {
        shake();
      }
    } else if ((!ev.shiftKey && ev.key === "Tab") || ev.key === "ArrowDown") {
      setActiveIndex((old) => (old + 1) % available.length);
      ev.preventDefault();
    } else if ((ev.shiftKey && ev.key === "Tab") || ev.key === "ArrowUp") {
      setActiveIndex((old) => (old === 0 ? available.length - 1 : old - 1));
      ev.preventDefault();
    } else if (
      ev.key === "Backspace" &&
      input.length === 0 &&
      value.length > 0
    ) {
      ev.preventDefault();
      onChange(value.slice(0, -1));
    }
  };

  return (
    <div style={{ position: "relative", flex: 1 }}>
      <LabelInput
        onChange={(ev) => setInput(ev.target.value)}
        items={selectedItems}
        value={input}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        onItemRemove={deselect}
        onKeyDown={onKeyDown}
      />
      {hasFocus && available.length > 0 && (
        <ListPanel>
          {available.map((x, i) => (
            <ListItem
              key={x.id}
              item={x}
              active={activeIndex === i}
              onMouseEnter={() => setActiveIndex(i)}
              onMouseDown={(ev) => ev.preventDefault()}
              onClick={() => select(i)}
            />
          ))}
        </ListPanel>
      )}
    </div>
  );
};
