import { SpecialtyFitmentDto } from "@oaktyres/model";
import React, { useState } from "react";
import { FaCartPlus } from "react-icons/fa";
import Button from "../Button";
import Flex from "../Flex";
import Text from "../Text";

export type InnertubeSectionProps = {
  fitment: SpecialtyFitmentDto | null | undefined;
  onAddToBasket: (stockCode: string, count: number) => void;
};

type CounterProps = {
  onAdd: (count: number) => void;
};

const Counter = ({ onAdd }: CounterProps) => {
  const [count, setCount] = useState(0);

  const inc = () => setCount(count + 1);

  const dec = () => setCount(Math.max(0, count - 1));

  const add = () => {
    const c = count;
    setCount(0);
    onAdd(c);
  };

  return (
    <Flex border={1} borderRadius={6} p={1} justifyContent="center">
      <Button minWidth={0} p={0} mr={2} width="24px" onClick={dec}>
        -
      </Button>
      <Flex flex={1} alignItems="center">
        <Text
          as="span"
          fontSize={1}
          lineHeight={1}
          textAlign={"center"}
          minWidth="20px"
        >
          {count}
        </Text>
      </Flex>
      <Button minWidth={0} p={0} ml={2} width="24px" mr={1} onClick={inc}>
        +
      </Button>
      <Button
        p={0}
        minWidth={0}
        onClick={add}
        disabled={count === 0}
        width="24px"
      >
        <FaCartPlus style={{ marginLeft: 0 }} />
      </Button>
    </Flex>
  );
};

export const InnertubeSection = ({
  fitment,
  onAddToBasket,
}: InnertubeSectionProps) => {
  if (!fitment?.innerTubes.length) {
    return null;
  }

  return (
    <Flex flexDirection={"column"}>
      <Text fontWeight={600} fontSize={1} color="grey2">
        Inner Tubes
      </Text>
      {fitment.innerTubes.map((x) => (
        <Flex flexDirection={"row"} mb={1} alignItems="center">
          <Text fontWeight={600} fontSize={1} style={{ flex: 1 }}>
            {x.description}
          </Text>
          <Counter onAdd={(count) => onAddToBasket(x.stockCode, count)} />
        </Flex>
      ))}
    </Flex>
  );
};
