import { formatSpecialtyFitment, SpecialtyTyreDto } from "@oaktyres/model";
import {
  Flex,
  formatCurrency,
  HelpPopover,
  Label as LabelElem,
  Panel,
  Text,
} from "@oaktyres/ui";
import React from "react";
import { FaRing, FaTruck } from "react-icons/fa";
import styled from "styled-components";
import { useRetailView } from "../../components/RetailViewProvider";

export type SpecialtyTyreCardProps = {
  product: SpecialtyTyreDto;
  supply: string;
  onClick?: React.MouseEventHandler;
  searchedFitment: string;
};

const Label = styled(LabelElem)`
  margin-left: 0 !important;
`;

const defaultProductImage = "https://via.placeholder.com/640x640";

const Container = styled(Panel)`
  border: 0;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale3d(1.02, 1.02, 1);
    box-shadow: ${(props) => props.theme.shadows[2]};
  }
`;

const ProductImageContainer = styled.div`
  position: relative;
`;

const ProductImage = styled.img`
  display: block;
  object-fit: contain;
  width: 100%;
  height: 200px;
  margin: 6px;
`;

const LogoContainer = styled.div`
  height: 18px;
  width: 160px;
  margin-bottom: 6px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`;

const InnerTubeLabel = styled(Label)`
  position: absolute;
  bottom: 6px;
  left: 12px;
`;

function getImage(product: SpecialtyTyreDto) {
  return product.pattern.images.length > 0
    ? `${process.env.REACT_APP_STATIC_ROOT}${product.pattern.images[0]}`
    : defaultProductImage;
}

const ProductPrice = ({ product }: { product: SpecialtyTyreDto }) => {
  let text =
    product.price?.value == null
      ? null
      : formatCurrency("gbp")(product.price.value);

  return (
    <Text fontWeight={600} fontSize="0.8em">
      {text ?? <>&nbsp;</>}
    </Text>
  );
};

export const SpecialtyTyreCard = ({
  product,
  onClick,
  supply,
  searchedFitment,
}: SpecialtyTyreCardProps) => {
  const [retailView] = useRetailView();

  const nextVan =
    supply === "delivery" && product.availability.some((x) => x.isNextRun);

  const availableNow = product.collectionAvailability.some(
    (x) => x.source.id === supply && x.availableNow,
  );

  const isInStock =
    supply === "delivery"
      ? product.availability.some(
          (x) => !x.orderedStock && !x.thirdParty && x.qtyAvailable > 0,
        )
      : product.collectionAvailability.some((x) => x.source.id === supply);

  const available =
    supply === "delivery" && product.availability.some((x) => x.orderedStock);

  const isAlternative =
    searchedFitment !== "" && product.fitment?.id !== searchedFitment;

  return (
    <Container overflow={"hidden"} onClick={onClick}>
      <Flex
        flexDirection={"column"}
        height="100%"
        justifyContent="space-between"
      >
        <ProductImageContainer>
          <ProductImage src={getImage(product)} />
          {product.innerTubeIncluded && (
            <InnerTubeLabel icon={FaRing} color="positive" labelSize="tiny">
              Inner tube included{" "}
              {product.innerTubeType == null
                ? null
                : `(${product.innerTubeType})`}
            </InnerTubeLabel>
          )}
        </ProductImageContainer>
        <Flex p={2} alignItems="flex-end" justifyContent="space-between">
          <Flex flexDirection="column" alignItems="">
            {product.brand.logo && (
              <LogoContainer>
                <img
                  src={process.env.REACT_APP_STATIC_ROOT + product.brand.logo}
                />
              </LogoContainer>
            )}
            <Text
              fontWeight={600}
              fontSize="0.9em"
              color="grey1"
              lineHeight={1.2}
            >
              {product.pattern.name}
            </Text>
            <Text fontWeight={600} fontSize="1em" mb={"3px"}>
              {formatSpecialtyFitment(product.fitment!)}{" "}
              {isAlternative && (
                <HelpPopover content="This fitment is an equivilant size for your search" />
              )}
              {product.alternateFitment != null && !isAlternative && (
                <Text as="span" fontSize={"0.8em"}>
                  {`(${formatSpecialtyFitment(product.alternateFitment)})`}
                </Text>
              )}
            </Text>
            <Flex alignItems="center" mb={1} flexWrap="wrap" style={{ gap: 3 }}>
              {nextVan ? (
                <Label color="positive" labelSize="tiny" icon={FaTruck}>
                  Next Van
                </Label>
              ) : availableNow ? (
                <Label color="positive" labelSize="tiny">
                  Available Now
                </Label>
              ) : isInStock ? (
                <Label color="info" labelSize="tiny">
                  In Stock
                </Label>
              ) : available ? (
                <Label color="info" labelSize="tiny">
                  Available
                </Label>
              ) : null}
              {product.eMark && <Label labelSize="tiny">E-Marked</Label>}
              {product.aramid && (
                <Label labelSize="tiny">{product.aramid}</Label>
              )}
              <Label labelSize="tiny">{product.ply} PR</Label>
              {product.speed > "K" && product.fitment?.construction === "-" && (
                <Label labelSize="tiny">High Speed</Label>
              )}
            </Flex>
            {!retailView && <ProductPrice product={product} />}
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};
