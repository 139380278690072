import {
  CollectionAvailability,
  PriceDto,
  TyreAvailability,
} from "../api/TyreSearchPayload";
import { InnerTubeType, SpecialtyFitmentDto } from "./fitments";

export const SpecialtyTreadStyles = {
  block: "Block",
  rib: "Rib",
  traction: "Traction",
  smooth: "Smooth",
  onroad: "On Road",
  directional: "Directional",
  nondirectional: "Non-Directional",
  agressive: "Aggressive",
} as const;

export type SpecialtyTreadStyleType = keyof typeof SpecialtyTreadStyles;

export type SpecialtyTyreSpec = {
  id?: string;
  stockCode: string;
  fitmentId: string | null;
  alternateFitmentId: string | null;
  loadSpeedOverride: string | null;
  aramid: string | null;
  productCategories: string[];
  tubeless: boolean;
  useNormalModal: boolean;
  eMark: boolean;
  innerTubeIncluded: boolean;
  innerTubeType: InnerTubeType | null;
};

export type SpecialtyTyreDto = {
  id: string;
  stockCode: string;
  fitment: SpecialtyFitmentDto | null;
  alternateFitment: SpecialtyFitmentDto | null;
  tubeless: boolean;
  eMark: boolean;
  innerTubeIncluded: boolean;
  innerTubeType: InnerTubeType | null;
  loadSpeedOverride: string | null;
  aramid: string | null;
  useNormalModal: boolean;
  productCategories: {
    id: string;
    name: string;
  }[];
  speed: string;
  load: string;
  ipc: string;
  ean: string;
  eprelId: number | null;
  ply: number;
  popularity: number;
  brand: {
    id?: number | null;
    crossReference: string;
    name: string | null;
    icon: string | null;
    tier: number | null;
    logo: string | null;
  };
  pattern: {
    id: number | null;
    crossReference: string;
    name: string;
    allTerrain: boolean | null;
    mudTerrain: boolean | null;
    highwayTerrain: boolean | null;
    track: boolean | null;
    directional: boolean | null;
    asymmetrical: boolean | null;
    threepeaks: boolean | null;
    trailer: boolean | null;
    cat: boolean | null;
    description: string | null;
    lightCommercial: boolean | null;
    logo: string | null;
    uhp: boolean | null;
    por: boolean | null;
    vintage: boolean | null;
    images: string[];
    specialtyTreadStyles: SpecialtyTreadStyleType[];
  };
  price?: PriceDto | null;
  availability: TyreAvailability[];
  collectionAvailability: CollectionAvailability[];
};
