import { DataSheetDto } from "@oaktyres/model";
import { getDownloadUrlForDataSheet } from "@oaktyres/queries";
import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import IconButton from "../IconButton";
import Loader from "../Loader";
import Modal from "../Modal";
import { PanelHeader } from "../Panel";
import Text from "../Text";

export type DataSheetModalProps = {
  dataSheet: DataSheetDto;
  onClose: () => void;
};

export const DataSheetModal = ({ dataSheet, onClose }: DataSheetModalProps) => {
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    getDownloadUrlForDataSheet(dataSheet.id, false).then((value) =>
      setUrl(value),
    );
  }, [dataSheet]);

  return (
    <Modal shown width={1000} height="90vh" onClose={onClose} p={0}>
      <PanelHeader justifyContent={"space-between"}>
        <Text fontSize={3} fontWeight={600}>
          Data Sheet
        </Text>
        <IconButton icon={FaTimes} onClick={onClose} color="body" />
      </PanelHeader>
      {url == null ? (
        <Loader />
      ) : (
        <embed src={url} type="application/pdf" height="100%" />
      )}
    </Modal>
  );
};
