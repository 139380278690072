export const innerTubeTypes = ["RV", "BMV"];

export type InnerTubeType = (typeof innerTubeTypes)[number];

export const tyreConstructionCodes = ["R", "-", "B", "D"];

export type TyreConstructionCode = (typeof tyreConstructionCodes)[number];

export type SpecialtyFitmentDto = {
  id: string;
  construction: TyreConstructionCode;
  tyreHeight: number | null;
  tyreWidth: number | null;
  rimWidth: number | null;
  rimDiameter: number;
  section: number | null;
  profile: number | null;
  prefix: string | null;
  suffix: string | null;

  stockCodes: string[];
  innerTubes: {
    stockCode: string;
    description: string;
  }[];
  aliases: string[];
};

export type SpecialtyFitmentSpec = Omit<
  SpecialtyFitmentDto,
  "id" | "stockCodes" | "innerTubes"
> & {
  id?: string;
  innerTubes: string[];
};

export const formatSpecialtyFitment = (
  fitment: SpecialtyFitmentDto | SpecialtyFitmentSpec,
) => {
  let result = "";

  result += fitment.prefix ? fitment.prefix : "";
  result += [fitment.section, fitment.profile]
    .filter((x): x is number => !!x)
    .map((x) => (Number.isInteger(x) ? x.toString() : x.toFixed(2)))
    .join("/");
  result += [
    fitment.tyreHeight,
    Number.isNaN(fitment.tyreWidth) ? "" : fitment.tyreWidth?.toFixed(2),
  ]
    .filter((x) => x)
    .join("x");

  result += fitment.rimWidth ? `/${fitment.rimWidth.toFixed(2)}` : "";
  result += [fitment.construction, fitment.rimDiameter, fitment.suffix]
    .filter((x) => x)
    .join("");

  return result;
};

export const isCrossPly = (code?: TyreConstructionCode) => code !== "R";
